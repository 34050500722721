import React, { useCallback, useEffect, useState } from 'react'

import api from '../../services/api'

import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useToast } from '../../hooks/toast'

import { Container, RankList, UserRankItem } from './styles'

import Avatar from '../../components/Avatar'
import formatValue from '../../utils/formatValue'
import Button from '../../components/Button'

interface RankingData {
  id: string
  user: {
    id: string
    avatar: string
    name: string
  }
  currentBudget: number
}

interface ResponseData {
  userPosition: number
  ranking: RankingData[]
}

const Ranking: React.FC = () => {
  const { setLayoutInfos } = useLayoutInfos()
  const { addToast } = useToast()

  const [pageToLoad, setPageToLoad] = useState(1)
  const [limitPerPage] = useState(10)
  const [rankingTotal, setRankingTotal] = useState(0)

  const [ranking, setRanking] = useState<RankingData[]>([] as RankingData[])
  const [userPosition, setUserPosition] = useState(999)

  useEffect(() => {
    setLayoutInfos({
      title: `Ranking do jogo`,
      description: `Compare seu patrimônio com a dos outros jogadores!`,
    })
  }, [setLayoutInfos])

  const prepareNextPage = useCallback(() => {
    setPageToLoad(pageToLoad + 1)
  }, [pageToLoad])

  const includeNewItemsToRanking = useCallback(
    (newItems: RankingData[]) => {
      setRanking([...ranking, ...newItems])
    },
    [ranking],
  )

  const loadMore = useCallback(async () => {
    addToast({ type: 'info', title: 'Carregando mais itens do Ranking Geral' })
    try {
      const response = await api.get(
        `/ranking/general?page=${pageToLoad}&limit=${limitPerPage}`,
      )
      const { ranking: allRanking } = response.data

      includeNewItemsToRanking(allRanking)

      prepareNextPage()
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Falha ao carregar ranking',
        description: err.message,
      })
    }
  }, [addToast, includeNewItemsToRanking, limitPerPage, pageToLoad, prepareNextPage])

  useEffect(() => {
    async function loadRanking(): Promise<void> {
      addToast({ type: 'info', title: 'Carregando Ranking Geral' })
      try {
        const response = await api.get(`/ranking/general?page=1&limit=${limitPerPage}`)

        const {
          ranking: allRanking,
          userPosition: usrPos,
          total: rankingTotalUpdated,
        } = response.data

        setUserPosition(usrPos)
        setRanking(allRanking)

        setRankingTotal(rankingTotalUpdated)

        setPageToLoad(2)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Falha ao carregar ranking',
          description: err.message,
        })
      }
    }

    loadRanking()
  }, [addToast, limitPerPage])

  return (
    <Container>
      <div>
        <h3>
          Você está em <strong>{userPosition}º</strong> lugar no ranking!
        </h3>
        <hr />
        <RankList>
          {ranking?.map((rank, idx) => (
            <UserRankItem key={rank.id}>
              <strong>{idx + 1}</strong>
              <div>
                <Avatar size={50} avatar={rank.user?.avatar} name={rank.user?.name} />
                {rank.user.name}
              </div>
              <p>{formatValue(rank.currentBudget)}</p>
            </UserRankItem>
          ))}
        </RankList>
        {rankingTotal > ranking.length && (
          <Button onClick={loadMore}>Carregar mais</Button>
        )}
      </div>
    </Container>
  )
}

export default Ranking
