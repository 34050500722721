import React from 'react'

import { AuthProvider } from './auth'
import { ToastProvider } from './toast'
import { UserStatsProvider } from './userStats'
import { MissionStatsProvider } from './missionStats'
// import { WorldStatsProvider } from './worldStats'
import { LayoutInfosProvider } from './layoutInfos'

const AppProvider: React.FC = ({ children }) => (
  <UserStatsProvider>
    {/* <WorldStatsProvider> */}
    <MissionStatsProvider>
      <AuthProvider>
        <ToastProvider>
          <LayoutInfosProvider>{children}</LayoutInfosProvider>
        </ToastProvider>
      </AuthProvider>
    </MissionStatsProvider>
    {/* </WorldStatsProvider> */}
  </UserStatsProvider>
)
export default AppProvider
