import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

// import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import Slider from 'react-slick'
import Button from '../../components/Button'
import { useAuth } from '../../hooks/auth'
import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useToast } from '../../hooks/toast'
import { useUserStats } from '../../hooks/userStats'
import api from '../../services/api'
import MissionItem from './components/MissionItem'
// import Dots from './components/Dots'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Container, DivContent, DivContentTrilha, Footer } from './styles'
import SampleArrow from '../../components/SampleArrow'

interface MissionStats {
  budget: number
  currentQuestionId: string
  missionId: string
  userId: string
  id: string
  finalizedAt?: Date
}

interface MissionData {
  id: string
  name: string
  description: string
  picture: string
  stats: MissionStats
  startedAt: Date
}

const Dashboard: React.FC = () => {
  const sliderRef = useRef<Slider>(null)

  const { user } = useAuth()
  const { userStats } = useUserStats()
  const history = useHistory()
  const { setLayoutInfos } = useLayoutInfos()

  const { addToast } = useToast()

  const [quantityOfMissionsAreFinalized, setQuantityOfMissionsAreFinalized] = useState(0)

  const [missions, setMissions] = useState<MissionData[]>([])

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleArrow direction="right" />,
    prevArrow: <SampleArrow direction="left" />,
    initialSlide: 0,
  }

  useEffect(() => {
    async function loadMissions(): Promise<void> {
      addToast({ type: 'info', title: 'Carregando Missões' })
      try {
        const response = await api.get<{ missions: MissionData[] }>('/missions')

        const { missions: allMissions } = response.data

        const missionsWithStats = await Promise.all(
          allMissions.map(async mission => {
            const missionWithStats: MissionData = {
              ...mission,
              stats: {} as MissionStats,
            }
            try {
              const responseStats = await api.get(`/missions/${mission.id}/stats`)
              const { missionStats } = responseStats.data

              missionWithStats.stats = missionStats
            } catch (err) {
              console.error(`Error to loading stats of mission ${mission.id}`)
            }
            return missionWithStats
          }),
        )

        setMissions(missionsWithStats)

        if (!allMissions.length) {
          throw new Error('Não existe missões.')
        }

        let actualFunded = false
        let actualMission = 0
        let finalizedMissionsCount = 0

        missionsWithStats.forEach((mission, idx) => {
          if (!actualFunded) {
            if (!mission.stats.finalizedAt) {
              actualMission = idx
              actualFunded = true
            }
          }
          if (mission.stats.finalizedAt) finalizedMissionsCount += 1
        })

        sliderRef?.current?.slickGoTo(actualMission)

        setQuantityOfMissionsAreFinalized(finalizedMissionsCount)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Falha ao carregar missões',
          description: err.message,
        })
      }
    }

    loadMissions()
  }, [addToast])

  useEffect(() => {
    setLayoutInfos({
      title: 'Bem-vindo!',
      description: `${user?.name.split(' ')[0]}
        , chegou a hora de aprender e testar seus conhecimentos!`,
    })
  }, [setLayoutInfos, user?.name])

  useEffect(() => {
    if (!user.userDetails?.firstAccess) {
      history.push('/infos')
    }
  }, [history, user.userDetails?.firstAccess])

  useEffect(() => {
    if (userStats && userStats.gameFinished) {
      history.push(`/game-finished`)
    }
  }, [history, userStats])

  // const handleStart = useCallback(() => {
  //   history.push(`/mission/${missions[missionActive].id}`, {
  //     mission: missions[missionActive],
  //   })
  // }, [history, missionActive, missions])

  const handleStart = useCallback(
    id => {
      history.push(`/mission/${id}/intro`, {
        mission: missions.filter(mission => mission.id === id)[0],
      })
    },
    [history, missions],
  )

  return (
    <>
      {userStats && !userStats.gameFinished && (
        <Container>
          <DivContentTrilha>
            {!!missions.length && (
              <>
                <h1>
                  {quantityOfMissionsAreFinalized} / {missions.length} finalizadas
                </h1>
                <Slider {...sliderSettings} ref={sliderRef}>
                  {missions.map((mission, idx) => (
                    <MissionItem
                      key={mission.id}
                      description={mission.description}
                      name={mission.name}
                      handleStart={handleStart}
                      picture={mission.picture}
                      id={mission.id}
                      idx={idx}
                      startedAt={mission.startedAt}
                      stats={mission.stats}
                    />
                  ))}
                </Slider>
              </>
            )}
          </DivContentTrilha>
        </Container>
      )}
    </>
  )
}

export default Dashboard
