import styled from 'styled-components'

import { shade } from 'polished'

import { Container as AvatarContainer } from '../../../../components/Avatar/styles'

export const Container = styled.aside``

export const DivAll = styled.div`
  /* padding: 15px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    padding: 15px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    color: ${props => props.theme.colors.text};

    > button {
      border: 0;
      background: none;
      height: 30px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      transition: all 0.2s;
      padding: 3px;

      color: ${props => props.theme.colors.text};
      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    /* width: 250px;
    padding: 20px; */
    > div {
      display: none;
    }
  }

  /* @media screen and (min-width: 1200px) {
    width: 350px;
    padding: 24px;
  }

  @media screen and (min-width: 1600px) {
    width: 450px;
    padding: 28px;
  } */
`

interface ContentProps {
  show: boolean
}

export const Content = styled.section<ContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 0px 10px; */
  transition: all 0.5s ease-in-out, transform 0.2s linear, margin 0s;

  transform: ${props => (props.show ? 'translateY(0)' : 'translateY(-100%)')};

  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  opacity: ${props => (props.show ? 1 : 0)};

  height: ${props => (props.show ? 'auto' : 0)};
  overflow: ${props => (props.show ? 'initial' : 'hidden')};

  margin-top: ${props => (props.show ? 15 : 0)}px;

  > div {
    flex: 1;
  }

  @media screen and (min-width: 610px) {
    flex-direction: row;
    justify-content: space-around;
  }

  @media screen and (min-width: 800px) {
  }

  @media screen and (min-width: 1100px) {
    flex-direction: column;
    transition: unset;
    visibility: visible;
    opacity: 1;
    height: auto;
    overflow: initial;
    margin-top: 0;
    transform: translateY(0);
    padding: 0px;
    > div {
      padding: 0;
      max-width: 100%;
    }
  }
`

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background-color: ${props => props.theme.colors.backgroundTertiary};
  border-radius: 10px;
  padding: 24px 16px;

  a {
    color: ${props => props.theme.colors.text};
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 18px;

    &:hover {
      opacity: 0.8;
      color: ${props => shade(0.2, props.theme.colors.text)};
    }

    ${AvatarContainer} {
      margin-bottom: 16px;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  @media screen and (min-width: 990px) {
    a {
      font-size: 21px;
      ${AvatarContainer} {
        margin-bottom: 18px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    &:after {
      margin: 28px 0;
    }

    a {
      flex-direction: row;

      font-size: 24px;
      ${AvatarContainer} {
        margin-bottom: unset;
        margin-right: 20px;
      }
    }
  }
`

export const DivRight = styled.div`
  display: flex;
  align-items: center;

  > article {
    display: flex;
    flex-direction: row;
    height: 70px;
    margin-top: 20px;
    margin-right: 16px;
    > img {
      width: 45px;
      margin-right: 12px;
    }
    > div {
      display: flex;
      flex-direction: column;

      > p {
        font-size: 18px;
        text-align: center;
      }
      > span {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  @media screen and (min-width: 768px) {
    > article {
      margin-top: unset;
    }
  }
`

export const XpGameStats = styled.div`
  display: flex;
  width: 100%;
  max-width: 250px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  font-size: 18px;
  font-weight: 700;

  img {
    width: 30px;
    margin-right: 12px;
  }

  @media screen and (min-width: 990px) {
    font-size: 20px;
    img {
      width: 40px;
      margin-right: 14px;
    }
  }

  @media screen and (min-width: 1200px) {
    font-size: 24px;
    img {
      width: 50px;
      margin-right: 16px;
    }
  }
`

export const GameStats = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &:after {
    content: '';
    width: 100%;
    display: block;
    height: 0px;
    border: 0.5px solid ${props => props.theme.colors.secondary};
    margin: 24px 0;
  }

  > div {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }

  @media screen and (min-width: 610px) {
    &:after {
      display: none;
    }
  }

  @media screen and (min-width: 990px) {
    &:after {
      display: block;
    }
  }

  @media screen and (min-width: 1200px) {
    &:after {
      margin: 28px 0;
    }
  }
`

export const ItemGameStats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
    margin-bottom: 12px;
  }
  font-size: 18px;
  font-weight: 700;

  @media screen and (min-width: 990px) {
    font-size: 20px;
    img {
      width: 40px;
      margin-bottom: 14px;
    }
  }

  @media screen and (min-width: 1200px) {
    font-size: 24px;
    img {
      width: 50px;
      margin-bottom: 16px;
    }
  }
`

export const WorldStats = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: '';
    width: 100%;
    display: block;
    height: 0px;
    border: 0.5px solid ${props => props.theme.colors.secondary};
    margin: 24px 0;
  }

  @media screen and (min-width: 990px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 24px;

    &:after {
      margin: 28px 0;
    }
  }
`

export const Dicas = styled.div`
  padding: 16px;
  border-radius: 10px;
  width: 100%;
  height: auto;
  min-height: 150px;
  background-color: ${props => props.theme.colors.backgroundTertiary};
  h2 {
    font-size: 18px;
    line-height: 21px;
    &:after {
      content: '';
      width: 100%;
      display: block;
      height: 0px;
      border: 0.5px solid ${props => props.theme.colors.tertiary};
      margin: 12px 0;
    }
  }
  p {
    font-size: 16px;
    line-height: 18px;
    text-align: justify;
  }
  strong {
    color: ${props => props.theme.colors.golden};
    font-size: 17px;
    text-transform: uppercase;
  }

  @media screen and (min-width: 1200px) {
    padding: 21px;
    h2 {
      font-size: 21px;
      line-height: 24px;

      &:after {
        margin: 16px 0;
      }
    }

    p {
      font-size: 18px;
      line-height: 21px;
    }
    strong {
      font-size: 19.5px;
    }
  }
`
