import { shade, tint } from 'polished'
import styled, { css } from 'styled-components'

import Tooltip from '../../../Tooltip'

interface ContainerProps {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
  sizeBox?: 'small' | 'large'
  hasSufix?: boolean
}

export const Container = styled.div<ContainerProps>`
  background: ${props => props.theme.colors.background};
  border-radius: 5px;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  width: 100%;

  position: relative;

  border: 1px solid ${props => shade(0.2, props.theme.colors.background)};
  color: ${props => props.theme.colors.placeholder};

  margin-bottom: 12px;
  transition: 0.2s;

  ${({ sizeBox }) =>
    sizeBox &&
    sizeBox === 'large' &&
    css`
      height: 70px;
      > label {
        font-size: 14px;
      }
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: ${props.theme.colors.errorText};
    `}

  > label {
    position: absolute;
    top: 16px;
    left: 12px;
    padding-right: 12px;

    color: ${({ theme }) => theme.colors.text};

    transition: 0.2s;
    pointer-events: none;
  }

  ${props =>
    props.isFocused &&
    css`
      border-color: ${props.theme.colors.blue};
      color: ${props.theme.colors.blue};
      > label {
        color: ${props.theme.colors.blue};
      }
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${props.theme.colors.blueSecondary};
      > label {
        color: ${props.theme.colors.blueSecondary};
      }
    `}



  ${props =>
    props.isErrored &&
    props.sizeBox &&
    props.sizeBox === 'large' &&
    css`
      > label {
        font-size: 13px;
        padding-right: 30px;
      }
    `}

  ${props =>
    (props.isFilled || props.isFocused) &&
    css`
      padding-top: 24px;
      ${props.sizeBox === 'large' &&
      css`
        padding-top: 33px;
      `}
      > label {
        transform: translateY(-12px);
        font-size: 12px;
        letter-spacing: 0.1em;
      }
    `}



  span {
    color: ${props => props.theme.colors.text};

    display: inline-block;

    margin-right: 4px;
  }

  input {
    appearance: none;
    color: ${props => props.theme.colors.text};
    flex: ${props => (!props.hasSufix ? 1 : 0)};
    text-align: ${props => (!props.hasSufix ? 'left' : 'right')};
    border: 0;
    background: transparent;

    &::placeholder {
      color: ${props => props.theme.colors.placeholder};
    }

    & + span {
      margin-right: 0px;
      margin-left: 4px;
    }
  }
  svg {
    margin-right: 12px;
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 12px;

  svg {
    margin: 0%;
  }

  span {
    background: ${props => props.theme.colors.errorText};
    color: #fff;

    &::before {
      border-color: ${props => props.theme.colors.errorText} transparent;
    }
  }
`

export const ButtonShowHidePass = styled.button`
  background-color: none;
  border: none;
`
