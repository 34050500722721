import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: ${props => props.theme.colors.background};

    -webkit-font-smoothing: antialiased;
    color: ${props => props.theme.colors.text};
  }

  body, input, button, textarea {
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
  }


  button {
    cursor: pointer;
    color: ${props => props.theme.colors.text};
  }


`
