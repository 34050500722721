import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 12px 16px rgba(101, 101, 101, 0.09);
  border: 1px solid #eaeaea;

  border-radius: 10px;
  padding: 12px 16px;

  > h1 {
    margin-bottom: 16px;
  }
  > p {
    margin-bottom: 8px;
  }

  margin-bottom: 25px;
`
