import styled from 'styled-components'
import { shade } from 'polished'

interface BtnProps {
  backgroundColor?: string
  textColor?: string
}

export const Container = styled.button<BtnProps>`
  background: ${props => props.backgroundColor ?? props.theme.colors.blue};
  height: 56px;
  border-radius: 10px;
  border: 0;
  padding: 0 16px;
  color: ${props => props.textColor ?? props.theme.colors.textSecondary};
  margin-top: 16px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${(props /* eslint-disable-next-line prettier/prettier */) =>
    shade(0.1, props.backgroundColor ?? props.theme.colors.blue)};
  }
`
