import React, { useEffect, useRef, useCallback, useState } from 'react'
import { FiMail, FiLock, FiUser, FiKey, FiPhone } from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Link, useHistory, useLocation } from 'react-router-dom'

import * as Yup from 'yup'

import { InputText } from '../../components/Form/Input'

import Button from '../../components/Button'

import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'

import { Container, Content, ContentBlock } from './styles'
import getValidationErrors from '../../utils/getValidationErrors'
import api from '../../services/api'

interface FormData {
  email: string
  password: string
  passwordConfirm: string
}

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

const Reset: React.FC = () => {
  const query = useQuery()

  const { setLayoutInfos } = useLayoutInfos()

  const { addToast } = useToast()

  const formLoginRef = useRef<FormHandles>(null)

  const [uprId, setUprId] = useState(() => query.get('uprid'))
  const [uprIdIsValid, setUprIdIsValid] = useState(false)
  const [validatingUprId, setValidatingUprId] = useState(true)

  const [usingAPI, setUsingAPI] = useState(false)
  const [recoverySended, setResetSended] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setLayoutInfos({
      title: 'Recuperação de senha',
      description: 'Defina sua nova senha!',
    })
  }, [setLayoutInfos])

  useEffect(() => {
    async function validateUprId(): Promise<void> {
      setValidatingUprId(true)
      try {
        const { data } = await api.get(`/recovery/password/${uprId}`)
        const { userPasswordRecovery } = data
        setUprIdIsValid(userPasswordRecovery.recoveredAt === null)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro na validação',
          description: 'Erro ao validar código de recuperação de senha.',
        })
      }
      setValidatingUprId(false)
    }
    validateUprId()
  }, [addToast, uprId])

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formLoginRef.current?.setErrors({})

        setUsingAPI(true)
        setResetSended(false)
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No mínimo 6 caracteres'),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password')], 'As senhas digitadas não conferem')
            .required('Repita a senha'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const response = await api.patch(`/users/password?uprid=${uprId}`, data)

        addToast({
          type: 'success',
          title: 'Recuperação finalizada',
          description: 'Sua senha foi alterada com sucesso. Você já pode fazer login.',
        })

        history.push(`/`)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formLoginRef.current?.setErrors(errors)
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao tentar recuperar sua senha, cheque seus dados e tente novamente.',
        })
      }
    },
    [addToast, history, uprId],
  )

  return (
    <Container>
      <Content>
        <ContentBlock>
          <Form ref={formLoginRef} onSubmit={handleSubmit}>
            <h1>Definir nova senha</h1>
            {!!validatingUprId && <p> Validando código de recuperação da senha...</p>}
            {!validatingUprId && !uprIdIsValid && (
              <p>
                Código de recuperação de senha inválido.
                <br /> Tente recuperar a senha novamente.
              </p>
            )}
            {!validatingUprId && uprIdIsValid && (
              <>
                <p>Confirme seu e-mail e defina sua nova senha abaixo.</p>
                <InputText name="email" icon={FiMail} placeholder="E-mail" type="email" />

                <InputText
                  name="password"
                  icon={FiLock}
                  type="password"
                  placeholder="Nova Senha"
                />
                <InputText
                  name="passwordConfirm"
                  icon={FiLock}
                  type="password"
                  placeholder="Repetir Nova Senha"
                />
                <footer>
                  <Button type="submit">Salvar senha</Button>
                  <Link to="/">Voltar para login</Link>
                </footer>
              </>
            )}
          </Form>
        </ContentBlock>
      </Content>
    </Container>
  )
}

export default Reset
