import React, { createContext, useCallback, useState, useContext } from 'react'

import api from '../services/api'

interface UserStats {
  experience: number
  currentBudget: number
  initialBudget: number
  id: string
  gameFinished: boolean
}

interface UserStatsContextData {
  userStats: UserStats

  setUserStats(userStats: UserStats): void
  updateUserStats(): void
  clearUserStats(): void
}

const UserStatsContext = createContext<UserStatsContextData>({} as UserStatsContextData)

const UserStatsProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<UserStats>(() => {
    const userStats = localStorage.getItem(
      `@${process.env.REACT_APP_PERSISTED_STATE_KEY}:userStats`,
    )

    if (userStats) {
      return JSON.parse(userStats)
    }
    return {} as UserStats
  })

  const setUserStats = useCallback(userStats => {
    localStorage.setItem(
      `@${process.env.REACT_APP_PERSISTED_STATE_KEY}:userStats`,
      JSON.stringify(userStats),
    )

    setData(userStats)
  }, [])

  const updateUserStats = useCallback(async () => {
    const response = await api.get('/users/userStats')
    setUserStats(response.data.userStats)
  }, [setUserStats])

  const clearUserStats = useCallback(() => {
    localStorage.removeItem(`@${process.env.REACT_APP_PERSISTED_STATE_KEY}:userStats`)
    setData({} as UserStats)
  }, [])

  return (
    <UserStatsContext.Provider
      value={{ userStats: data, setUserStats, updateUserStats, clearUserStats }}
    >
      {children}
    </UserStatsContext.Provider>
  )
}

function useUserStats(): UserStatsContextData {
  const context = useContext(UserStatsContext)
  if (!context) {
    throw new Error('useUserStats must be used within an GameStatsProvider')
  }
  return context
}

export { UserStatsProvider, useUserStats }
