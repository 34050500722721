import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  height: 100%;

  max-width: 420px;
  margin-top: -25px;
  padding-top: 25px;

  @media screen and (min-width: 766px) {
    max-width: 650px;
  }
  @media screen and (min-width: 992px) {
    max-width: 850px;
  }
`

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 10px 15px;

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 30px;
    > div {
      > span {
        font-size: 12px;
      }

      > h3 {
        font-size: 16px;
        font-weight: bold;
        margin: 4px 0px;
      }

      > small {
        font-size: 9px;
      }
    }
  }
  article {
    text-align: center;

    max-width: 325px;

    p {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 766px) {
    header {
      margin-bottom: 35px;
      > div {
        > span {
          font-size: 13px;
        }
        > h3 {
          font-size: 17px;
          margin: 5px 0px;
        }
        > small {
          font-size: 10px;
        }
      }
    }
    article {
      max-width: 350px;
      p {
        font-size: 14px;
      }
    }
  }
`

export const GraphWrapper = styled.div`
  display: block;
  height: 285px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;

  @media screen and (min-width: 766px) {
    height: 300px;
    margin: 22px 0px;
  }
`
