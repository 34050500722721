import React, { ReactNode } from 'react'
import { RiArrowRightCircleFill, RiArrowLeftCircleFill } from 'react-icons/ri'

import { Container } from './styles'

interface SampleArrowProps {
  onClick?: () => void
  direction: 'left' | 'right'
  className?: string
}
const SampleArrow: React.FC<SampleArrowProps> = ({ onClick, direction, className }) => {
  const Arrow = direction === 'left' ? RiArrowLeftCircleFill : RiArrowRightCircleFill
  return (
    <Container className={className} onClick={onClick}>
      <Arrow size={30} />
    </Container>
  )
}

export default SampleArrow
