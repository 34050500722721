import styled from 'styled-components'

interface ContainerProps {
  size: number
  sizeToggled: number
  toggled: boolean
}

export const Container = styled.div<ContainerProps>`
  display: block;
  height: ${props => (props.toggled ? props.sizeToggled : props.size)}px;
  width: ${props => (props.toggled ? props.sizeToggled : props.size)}px;

  img {
    object-position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
`
