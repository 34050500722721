import React, { useCallback, useState } from 'react'
import Button from '../../../../components/Button'
import CoinsAndValue from '../../../../components/CoinsAndValue'
import { useMissionStats } from '../../../../hooks/missionStats'
import { useToast } from '../../../../hooks/toast'
import { useUserStats } from '../../../../hooks/userStats'
import api from '../../../../services/api'

import { Container, GroupButtons } from './styles'

interface Question {
  id: string
  missionId: string
  questionTypeId: string
  budgetValue: number
}

interface QuizQuestionOption {
  id: string
  option: string
  isAnswer: boolean
  quizQuestionId: string
}

interface QuizQuestion {
  id: string
  questionId: string
  questionContent: string
  quizQuestionOptions: QuizQuestionOption[]
}

export interface QuestionWithContent extends Question {
  questionData: QuizQuestion
}

interface Props {
  question: QuestionWithContent
}

interface AnswerProps {
  isCorrect: boolean
}

const Quiz: React.FC<Props> = ({ question }) => {
  const { missionStats, updateMissionStats } = useMissionStats()
  const { updateUserStats } = useUserStats()
  const { addToast } = useToast()

  const [answered, setAnswered] = useState(false)

  const [lastAnswer, setLastAnswer] = useState<AnswerProps>({} as AnswerProps)

  const handleSubmitAnswer = useCallback(
    async (quizQuestionOptionId: string) => {
      try {
        const response = await api.post(
          `/missions/${question.missionId}/questions/${question.id}/answers`,
          {
            answer: {
              quizQuestionOptionId,
              quizQuestionId: question.questionData.id,
              missionStatsId: missionStats.id,
            },
          },
        )

        const { answer } = response.data
        setLastAnswer(answer)

        updateUserStats()
        setAnswered(true)
        // updateMissionStats(question.missionId)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Falha ao salvar resposta',
          description: err.message,
        })
      }
    },
    [
      addToast,
      missionStats.id,
      question.id,
      question.missionId,
      question.questionData.id,
      updateUserStats,
    ],
  )

  const handleContinue = useCallback(async () => {
    if (answered) {
      await updateMissionStats(question.missionId)
      setAnswered(false)
    }
  }, [answered, question.missionId, updateMissionStats])

  return (
    <Container>
      <header>
        <p>Quiz</p>
      </header>
      <main>
        {!answered && (
          <>
            <p>{question.questionData.questionContent}</p>

            <GroupButtons direction="column">
              {question.questionData.quizQuestionOptions.map(option => (
                <Button
                  type="button"
                  backgroundColor="green"
                  onClick={() => handleSubmitAnswer(option.id)}
                >
                  {option.option}
                </Button>
              ))}
            </GroupButtons>
          </>
        )}
        {answered && (
          <>
            {lastAnswer?.isCorrect && (
              <>
                <h1>Parabéns!</h1>
                <p>A sua resposta está correta!</p>
              </>
            )}
            {!lastAnswer?.isCorrect && (
              <>
                <h1>Você errou!</h1>
                <p>
                  A resposta certa é:
                  <br />
                  <i>
                    {
                      question.questionData.quizQuestionOptions.filter(
                        option => option.isAnswer === true,
                      )[0].option
                    }
                  </i>
                </p>
              </>
            )}
            <GroupButtons direction="row">
              <Button type="button" onClick={handleContinue}>
                Continuar
              </Button>
            </GroupButtons>
          </>
        )}
      </main>
      <footer>
        <p>Essa questão vale</p>
        <CoinsAndValue value={question.budgetValue} text="Patrimônio" />
      </footer>
    </Container>
  )
}

export default Quiz
