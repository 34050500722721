import styled from 'styled-components'
import bgWithFooterMobile from '../../assets/bg-with-footer-mobile.png'
import bgWithFooterTablet from '../../assets/bg-with-footer-tablet.png'
import bgWithFooter from '../../assets/bg-with-footer.png'
import bgWood from '../../assets/bg-wood.png'

export const Container = styled.div`
  background-image: url(${bgWithFooterMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height: 100vh;
  display: flex;
  width: 100%;
  justify-content: center;
  @media (min-width: 768px) {
    background-image: url(${bgWithFooterTablet});
  }
  @media (min-width: 990px) {
    background-image: url(${bgWithFooter});
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  min-height: 100vh;
  > img {
    margin-bottom: 15px;
    width: 70%;
  }

  @media (min-width: 990px) {
    padding-top: 15px;
    > img {
      margin-bottom: 20px;
      width: 725px;
    }
  }
`

const heightByWidth = 1.320441989 // 1195/905

export const WoodBoard = styled.main`
  height: calc(85vw * ${heightByWidth});

  width: 85vw;
  background-image: url(${bgWood});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  padding: 7vw 9vw 10vw 8vw;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;

  > p {
    color: yellow;
    font-size: 16px;
    text-align: justify;
    width: 100%;
    display: none;
  }

  @media (min-width: 768px) {
    padding: 9vw 9vw 10vw 8vw;
    > p {
      display: block;
    }
  }

  @media (min-width: 990px) {
    height: 1270px;
    width: 970px;

    padding: 130px 100px 100px 110px;
    margin-bottom: 15px;

    > p {
      font-size: 18px;
      width: 640px;
    }
  }
`

export const DivButtons = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 10px;

  > button {
    background: transparent;
    border: none;
    > img {
      max-width: 90%;
      max-height: 80px;
    }
  }

  @media (min-width: 990px) {
    display: flex;
    height: 200px;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    > button {
      background: transparent;
      border: none;

      > img {
        width: unset;
        max-height: unset;
      }
    }
  }
`
export const DivPremios = styled.div`
  display: flex;
  height: calc(57vw * ${heightByWidth});
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    height: auto;
    width: auto;
    max-width: 30vw;
    display: none;
  }

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    article {
      display: flex;
      flex-direction: column;
      align-items: center;
      > img {
        height: 50px;
      }
      > div {
        color: yellow;
        margin: 10px 0;
        font-size: 14px;
        ol {
          list-style: none;
          /* list-style-position: inside; */
          counter-reset: my-awesome-counter 0;
          padding-left: 25px;
          li {
            counter-increment: my-awesome-counter;
            padding: 0 0 0 0.5em;

            &::marker {
              content: counter(my-awesome-counter) ' -';
              color: yellow;
              font-weight: normal;
            }
          }
        }
      }
    }
    > p {
      color: white;
      text-align: center;
      font-size: 12px;
    }
  }
  @media (min-width: 750px) {
    height: calc(52vw * ${heightByWidth});
    > img {
      display: block;
    }
    > div {
      article {
        > div {
          font-size: 18px;
        }
      }
      > p {
        font-size: 18px;
      }
    }
  }

  @media (min-width: 990px) {
    height: 600px;
    width: 100%;

    > img {
      height: 490px;
      width: auto;
      max-width: unset;
    }

    > div {
      article {
        > img {
          height: 78px;
        }
        > div {
          margin-top: 25px;
          margin-bottom: 60px;
        }
      }
      > p {
        font-size: 24px;
      }
    }
  }
`

export const Footer = styled.footer`
  display: flex;
  height: 650px;
  margin-top: 50px;
  flex-direction: column;
  > div {
    font-weight: bold;
  }

  @media (min-width: 768px) {
    height: 450px;
  }

  @media (min-width: 990px) {
    height: 300px;
    margin-top: 180px;
  }
`

export const DivEntidades = styled.div`
  color: black;
  margin-top: 32px;
  > p {
    width: 100%;
  }

  > div {
    margin: 15px 5px 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px 0px;
    align-items: center;
    justify-items: center;

    img {
      max-height: 45px;
      max-width: 140px;
    }
  }

  @media (min-width: 768px) {
    > div {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (min-width: 990px) {
    color: white;
    > p {
      width: 110px;
    }

    > div {
      grid-template-columns: repeat(7, 1fr);
      max-width: 900px;
    }
  }
`

export const DivPatrocinadores = styled.div`
  color: black;
  display: flex;
  align-items: left;
  flex-direction: column;
  > p {
    width: 100%;
  }

  > div {
    margin: 15px 5px 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    align-items: center;
    justify-items: left;

    img {
      max-height: 45px;
      max-width: 110px;
    }
  }

  @media (min-width: 768px) {
    > div {
      max-width: 300px;
      grid-template-columns: repeat(3, 1fr);
      justify-items: right;
    }
  }

  @media (min-width: 990px) {
    flex-direction: row;
    align-items: center;
    > p {
      width: 120px;
      margin-right: 16px;
    }
    > p {
      width: 110px;
    }
  }
`
