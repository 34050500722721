import styled from 'styled-components'

interface ContainerProps {
  background?: string
}

export const Container = styled.div<ContainerProps>`
  min-height: 100vh;
  padding-top: 90px;
  background: url(${props => props.theme.colors.background}) no-repeat center center;
  color: ${props => props.theme.colors.text};
`
export const OutHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 100px);
`

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1600px;

  justify-content: center;
  align-content: center;

  padding: 10px 15px;

  /*@media screen and (min-width: 1100px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1200px) {
    padding: 30px 25px;
  }
  @media screen and (min-width: 1600px) {
    padding: 45px 35px;
  }*/
`
