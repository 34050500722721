/* eslint-disable prettier/prettier */
const questionTypes =
  process.env.NODE_ENV === 'development'
    ? {
      truthOrMythId: 'fa2252fe-d0fe-4fd1-8de0-213354bf41c5',
      positiveNegativeId: '20221e52-3a7f-4dc7-972a-4d469f981f8f',
      quizId: '5a5bd475-f75d-4bea-9bd2-5367efbae4f7',
    }
    : {
      truthOrMythId: '6bd3e89d-e8b8-4219-bfb4-953e8330ff6f',
      positiveNegativeId: '37f98be3-f26b-4db8-afe0-27ff10882ce7',
      quizId: 'a720bb2f-4f48-4ed5-b5b0-ad36fc0f769a',
    }

export default questionTypes


