import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useLayoutInfos } from '../../hooks/layoutInfos'

import { useToast } from '../../hooks/toast'

import { Container } from './styles'
import api from '../../services/api'
import Button from '../../components/Button'

interface MissionData {
  id: string
  name: string
  description: string
  picture: string
  intro: string
}

const GameIntro: React.FC = () => {
  const { setLayoutInfos } = useLayoutInfos()
  const { missionId } = useParams<{ missionId: string }>()
  const { addToast } = useToast()
  const history = useHistory()

  const [mission, setMission] = useState<MissionData>({} as MissionData)

  useEffect(() => {
    async function loadMission(): Promise<void> {
      try {
        const response = await api.get(`/missions/${missionId}`)

        const { mission: thisMission } = response.data

        setMission(thisMission)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar missão',
          description: err.message,
        })

        history.push('/dashboard')
      }
    }

    loadMission().then(() => {
      setLayoutInfos({
        title: mission?.name,
        description: mission?.description,
      })
    })
  }, [addToast, history, mission?.description, mission?.name, missionId, setLayoutInfos])

  const handleStartGame = useCallback(async () => {
    try {
      await api.post(`/missions/${missionId}/start`)
    } catch (err) {
      // addToast({
      //   type: 'error',
      //   title: 'Falha ao startar missão',
      //   description: err.message,
      // })
    }

    history.push(`/mission/${missionId}/game`)
  }, [history, missionId])

  return (
    <Container>
      <p
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: mission.intro }}
      />
      <Button type="button" onClick={handleStartGame}>
        Jogar
      </Button>

      <Button type="button" onClick={() => history.push(`/dashboard`)}>
        Página Inicial
      </Button>
    </Container>
  )
}

export default GameIntro
