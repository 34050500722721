import styled, { css } from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${props => props.theme.colors.backgroundSecondary};
  }

  main {
    max-width: 550px;

    margin: 6px 0px 36px;
    h1 {
      text-align: center;
    }
    p {
      font-size: 22px;
      text-align: justify;
    }
  }
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

type GroupButtonsProps = {
  direction: 'column' | 'row'
}
export const GroupButtons = styled.div<GroupButtonsProps>`
  margin-top: 16px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: ${props => props.direction};
  ${props =>
    props.direction === 'column'
      ? css`
          button {
            width: 100%;
          }
          button + button {
            margin-top: 25px;
            width: 100%;
          }
        `
      : css`
          button {
            margin-top: 0;
          }
        `}
`
