import React, { useEffect, useRef, useCallback, useState } from 'react'
import { FiMail, FiLock, FiUser, FiKey, FiPhone } from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Link, useHistory, useLocation } from 'react-router-dom'
import isBefore from 'date-fns/isBefore'

import * as Yup from 'yup'

import { InputDate, InputText } from '../../components/Form/Input'

import regulamentoPdf from '../../assets/regulamento.pdf'
import Button from '../../components/Button'

import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'

import {
  Container,
  Content,
  TabLinks,
  ButtonTab,
  TabContent,
  TabItem,
  ContentRegras,
} from './styles'
import getValidationErrors from '../../utils/getValidationErrors'
import api from '../../services/api'
import Select from '../../components/Form/Select'

interface SignInFormData {
  email: string
  password: string
}
interface SignUpFormDate {
  name: string
  email: string
  cpf: string
  password: string
  passwordConfirm: string
  birthdate: Date
  phone: string
}

type Entity = {
  name: string
  id: string
}
type OptionSelect = {
  value: string
  label: string
}

const Home: React.FC = () => {
  const [entities, setEntities] = useState<Entity[]>([] as Entity[])
  const [optionsSelect, setOptionsSelect] = useState<OptionSelect[]>([] as OptionSelect[])

  const { setLayoutInfos } = useLayoutInfos()

  const { signIn } = useAuth()
  const { addToast } = useToast()

  const formLoginRef = useRef<FormHandles>(null)
  const formRegisterRef = useRef<FormHandles>(null)

  const [activeTab, setActiveTab] = useState<'left' | 'right' | null>('left')

  const [usingAPI, setUsingAPI] = useState(false)
  const [registeredEmail, setRegisteredEmail] = useState('')

  const history = useHistory()

  const entidades = [
    'BRF Previdência',
    'Casanprev',
    'Celos',
    'Datusprev',
    'Elos',
    'Fumpresc',
    'Fusesc Oabprev - SC',
    'Previg',
    'Previsc',
    'Prevunisul',
    'Quanta',
    'SCPREV',
    'Sul Previdência',
  ]

  useEffect(() => {
    setLayoutInfos({
      title: 'Bem-vindo!',
      description: 'Faça login ou realize seu cadastro para acessar a plataforma!',
    })
  }, [setLayoutInfos])

  useEffect(() => {
    async function loadEntities(): Promise<void> {
      // addToast({ type: 'info', title: 'Carregando Entidades' })
      try {
        const response = await api.get('/entities')

        const { entities: allEntities } = response.data

        setEntities(allEntities)

        if (!allEntities.length) {
          throw new Error('Não existe entidades.')
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Falha ao carregar entidades',
          description: err.message,
        })
      }
    }

    loadEntities()
  }, [addToast])

  useEffect(() => {
    setOptionsSelect(entities.map(entity => ({ value: entity.id, label: entity.name })))
  }, [entities])

  const changeActiveTab = useCallback(
    (newActive: 'left' | 'right') => {
      if (!usingAPI) {
        setActiveTab(newActive)
      }
    },
    [usingAPI],
  )

  const handleLoginSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formLoginRef.current?.setErrors({})

        setUsingAPI(true)
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().required('Senha Obrigatória'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await signIn({ email: data.email, password: data.password })

        setUsingAPI(false)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formLoginRef.current?.setErrors(errors)
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, cheque seus dados e tente novamente.',
        })

        setUsingAPI(false)
      }

      setUsingAPI(false)
    },
    [signIn, addToast],
  )

  const handleRegisterSubmit = useCallback(
    async (data: SignUpFormDate) => {
      try {
        formRegisterRef.current?.setErrors({})

        setUsingAPI(true)
        const schema = Yup.object().shape({
          entityId: Yup.string().required('Entidade é obrigatório'),
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No mínimo 6 caracteres'),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password')], 'As senhas digitadas não conferem')
            .required('Repita a senha'),
          // cpf: Yup.string().required('CPF é obrigatório'),
          // birthdate: Yup.date()
          //   .min(new Date(1900, 0, 1), 'Deve ser maior que 01/01/1900')
          //   .max(new Date(), 'Deve ser menor que a data de hoje.')
          //   .required('Data de nascimento obrigatória'),
          // phone: Yup.string()
          //   .min(14, 'Deve ter ao menos 10 algarismos')
          //   .max(15, 'Deve ter até 11 algarismos'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await api.post('/users', data)
        addToast({
          type: 'success',
          title: 'Cadastro efetuado',
          description: 'Faça login para acessar a plataforma.',
        })

        setActiveTab('left')
        setUsingAPI(false)
        setRegisteredEmail(data.email)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRegisterRef.current?.setErrors(errors)
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: err.response?.data?.message ?? err.message,
        })

        setUsingAPI(false)
      }

      setUsingAPI(false)
    },
    [addToast],
  )

  return (
    <Container>
      {isBefore(new Date(), new Date(2021, 11, 23, 0)) ? (
        <>
          <Content>
            <TabLinks>
              <ButtonTab
                active={activeTab === 'left'}
                position="left"
                type="button"
                onClick={() => changeActiveTab('left')}
              >
                Entrar
              </ButtonTab>
              <ButtonTab
                active={activeTab === 'right'}
                position="right"
                type="button"
                onClick={() => changeActiveTab('right')}
              >
                Cadastrar
              </ButtonTab>
            </TabLinks>
            <TabContent>
              <TabItem active={activeTab === 'left'}>
                <Form
                  ref={formLoginRef}
                  onSubmit={handleLoginSubmit}
                  initialData={{ email: registeredEmail }}
                >
                  <h1>Faça seu login</h1>
                  <InputText
                    name="email"
                    icon={FiMail}
                    placeholder="E-mail"
                    type="email"
                  />
                  <InputText
                    name="password"
                    icon={FiLock}
                    type="password"
                    placeholder="Senha"
                  />
                  <footer>
                    <Button type="submit">Entrar</Button>
                    <Link to="/recovery">Recuperar Senha</Link>
                  </footer>
                </Form>
              </TabItem>
              <TabItem active={activeTab === 'right'}>
                <Form ref={formRegisterRef} onSubmit={handleRegisterSubmit}>
                  <h1>Faça seu cadastro</h1>
                  <InputText name="name" icon={FiUser} placeholder="Nome" type="text" />
                  <InputText
                    name="email"
                    icon={FiMail}
                    placeholder="E-mail"
                    type="email"
                  />
                  {/* <InputText
                name="cpf"
                mask="cpf"
                icon={FiKey}
                type="tel"
                placeholder="CPF"
              />
              <InputDate
                name="birthdate"
                icon
                min="1900-00-01"
                placeholder="Data de nascimento"
              />
              <InputText
                name="phone"
                icon={FiPhone}
                mask="phone"
                type="tel"
                placeholder="Telefone com DDD"
              /> */}
                  <InputText
                    name="password"
                    icon={FiLock}
                    type="password"
                    placeholder="Senha"
                  />
                  <InputText
                    name="passwordConfirm"
                    icon={FiLock}
                    type="password"
                    placeholder="Repetir Senha"
                  />
                  <Select
                    name="entityId"
                    placeholder="Selecione uma Entidade"
                    options={optionsSelect}
                  />
                  <Button type="submit">Cadastrar</Button>
                </Form>
              </TabItem>
            </TabContent>
          </Content>
          <ContentRegras>
            <h2>Regras do Jogo</h2>
            <p>
              Após você realizar seu cadastro, você irá começar a jogar.Existem diversos
              tipos de jogos, alguns com conteúdo educacional sobre algum tema específico
              e outros que buscam compreender como você se comporta bem
              financeiramente.Com base nas suas respostas, o patrimônio vai aumentar ou
              diminuir.
            </p>
            <p>
              Esse game foi criado para você aprender um pouco mais sobre previdência e
              refletir sobre como você lida com o dinheiro.Para que o objetivo do jogo
              seja cumprido, é fundamental que você jogue da maneira mais sincera
              possível.
            </p>
            <p>
              Inventamos uma moeda exclusiva para este game, chamada de CertezaCoin
              (C$).Ela ajudará a medir o quanto você tem bons hábitos financeiros.
            </p>
            <p>
              Com base no seu desempenho, seu patrimônio dentro do jogo vai
              alterando.Todos começam com C$ 0,00.Caso você acerte a resposta seu
              patrimônio aumenta, caso erre seu patrimônio diminui.Quanto mais rápido você
              responder de forma correta, você também ganha um bônus de C$.Os 10 melhores
              jogadores ganharam prêmios, desde que esses sejam participantes de uma das
              entidades associadas à ASCPrev.
            </p>

            <p>
              <strong>Prêmios: </strong>
              <br />
              Prêmios de acordo com a colocação:
            </p>
            <ol>
              <li> - Smart TV de 43&quot; </li>
              <li> - Bicicleta ergométrica </li>
              <li> - Robo aspirador </li>
              <li> - Nespresso </li>
              <li> - Air Fryer </li>
              <li> - um ano de HBO Max </li>
              <li> - um ano assinatura Amazona Prime </li>
              <li> - um ano assinatura Amazona Prime </li>
              <li> - um ano assinatura Amazona Prime </li>
              <li> - um ano assinatura Amazona Prime</li>
            </ol>
            <p>
              <button type="button" onClick={() => window.open(regulamentoPdf)}>
                Clique aqui para ler o regulamento completo
              </button>
            </p>
          </ContentRegras>
        </>
      ) : (
        <p>O jogo acabou! estamos computado os resultados.</p>
      )}
    </Container>
  )
}

export default Home
