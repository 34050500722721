import { compareDesc, formatDistanceToNow, isBefore } from 'date-fns/esm'
import ptBR from 'date-fns/esm/locale/pt-BR/index.js'

import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import Button from '../../../../components/Button'
import api from '../../../../services/api'

import { Container, ContainerInside, CounterIdx } from './styles'

interface Stats {
  budget: number
  currentQuestionId: string
  missionId: string
  userId: string
  id: string
  finalizedAt?: Date
}

interface Props {
  id: string
  name: string
  description: string
  picture: string
  handleStart(id: string): void
  idx: number
  stats: Stats
  startedAt: Date
}

const MissionItem: React.FC<Props> = ({
  id,
  idx,
  name,
  description,
  picture,
  handleStart,
  stats,
  startedAt,
}) => {
  const { colors } = useContext(ThemeContext)
  const today = new Date()
  const startedAtDate = new Date(startedAt)

  const notStarted = isBefore(today, startedAtDate)

  return (
    <Container>
      <ContainerInside>
        <figure>
          <img src={`${process.env.REACT_APP_API_URL}/files/${picture}`} alt={name} />
        </figure>
        <p>
          <strong>{name}</strong>
        </p>
        {/* <p>{description}</p> */}
        {notStarted ? (
          <p>Começa em: {formatDistanceToNow(startedAtDate, { locale: ptBR })}</p>
        ) : (
          <Button
            onClick={() => handleStart(id)}
            backgroundColor={colors.backgroundSecondary}
          >
            {!!stats && stats.finalizedAt ? 'Missão Finalizada' : 'Começar'}
          </Button>
        )}
        <CounterIdx>{idx + 1}</CounterIdx>
      </ContainerInside>
    </Container>
  )
}

export default MissionItem
