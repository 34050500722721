import styled from 'styled-components'

export const Container = styled.div`
  padding: 25px 20px;

  h1 {
    text-align: center;
    margin-bottom: 21px;
  }

  > header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
    h3 {
      margin-bottom: 16px;
      text-align: center;
    }
    > p {
      text-align: center;
    }
  }

  p {
    margin-bottom: 12px;
  }
  main {
    max-width: 800px;
  }

  ol {
    margin-left: 4px;

    list-style: none;
    counter-reset: my-awesome-counter;

    li {
      counter-increment: my-awesome-counter;
      text-align: justify;
      display: flex;

      &::before {
        content: '0' counter(my-awesome-counter) ' ';
        color: ${props => props.theme.colors.backgroundSecondary};
        font-weight: bold;
        font-size: 28px;
        margin-right: 8px;
      }

      &:nth-child(n + 10) {
        &::before {
          content: counter(my-awesome-counter) ' ';
          color: ${props => props.theme.colors.backgroundSecondary};
          font-weight: bold;
          font-size: 28px;
          margin-right: 8px;
        }
      }

      & + li {
        margin-top: 12px;
      }
    }
  }
`
