type Event = React.FormEvent<HTMLInputElement>

const cpf = (e: Event): Event => {
  e.currentTarget.maxLength = 14

  let { value } = e.currentTarget

  value = value.replace(/\D/g, '')

  value = value.replace(/(\d)(\d{2})$/, '$1-$2')
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')

  // value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')

  e.currentTarget.value = value

  return e
}

const cep = (e: Event): Event => {
  e.currentTarget.maxLength = 9

  let { value } = e.currentTarget

  value = value.replace(/\D/g, '')
  value = value.replace(/^(\d{5})(\d)/, '$1-$2')

  e.currentTarget.value = value

  return e
}

const currency = (e: Event): Event => {
  let { value } = e.currentTarget

  value = value.replace(/\D/g, '')
  value = value.replace(/(\d)(\d{2})$/, '$1,$2')
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.')

  e.currentTarget.value = value

  return e
}

const phone = (e: Event): Event => {
  e.currentTarget.maxLength = 15

  let { value } = e.currentTarget

  value = value.replace(/\D/g, '')

  // value = value.replace(/(\d{2})(\d)?(\d{4})(\d{4})$/, '($1) $2$3-$4')

  value = value.replace(/^(\d{2})(\d)/g, '($1) $2')

  value = value.replace(/(\d)(\d{4})$/, '$1-$2')

  e.currentTarget.value = value

  return e
}

interface MaskFunctionValues {
  type: 'cep' | 'currency' | 'cpf' | 'phone'
  event: Event
}

export default ({ type, event }: MaskFunctionValues): Event => {
  const masks = {
    cep: (e: Event) => cep(e),
    currency: (e: Event) => currency(e),
    cpf: (e: Event) => cpf(e),
    phone: (e: Event) => phone(e),

    default: event,
  }

  return (masks[type] || masks.default)(event)
}
