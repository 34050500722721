import React, { useEffect, useRef, useCallback, useState } from 'react'
import { FiMail } from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { Link, useHistory } from 'react-router-dom'

import * as Yup from 'yup'

import { InputText } from '../../components/Form/Input'

import Button from '../../components/Button'

import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'

import { Container, Content, ContentBlock } from './styles'
import getValidationErrors from '../../utils/getValidationErrors'
import api from '../../services/api'

interface FormData {
  email: string
}

const Recovery: React.FC = () => {
  const { setLayoutInfos } = useLayoutInfos()

  const { signIn } = useAuth()
  const { addToast } = useToast()

  const formLoginRef = useRef<FormHandles>(null)

  const [usingAPI, setUsingAPI] = useState(false)
  const [recoverySended, setRecoverySended] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setLayoutInfos({
      title: 'Recuperação de senha',
      description: 'Digite seu e-mail para recuperar sua senha!',
    })
  }, [setLayoutInfos])

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formLoginRef.current?.setErrors({})

        setUsingAPI(true)
        setRecoverySended(false)
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await api.post('/recovery/password', data)

        addToast({
          type: 'success',
          title: 'Recuperação enviada',
          description:
            'Em instantes você receberá em seu e-mail um link para recuperar a senha.',
        })
        setRecoverySended(true)
        setUsingAPI(false)
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formLoginRef.current?.setErrors(errors)
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao tentar recuperar sua senha, cheque seus dados e tente novamente.',
        })
      }
    },
    [addToast],
  )

  return (
    <Container>
      <Content>
        <ContentBlock>
          {!!recoverySended && (
            <>
              <h2>E-mail de recuperação enviado.</h2>
              <p>
                Verifique seu e-mail e clique no link recebido para definir uma nova
                senha.
                <br />O link de recuperação é válido por 1 hora.
              </p>
            </>
          )}
          {!recoverySended && (
            <Form ref={formLoginRef} onSubmit={handleSubmit}>
              <h1>Recuperar Senha</h1>
              <p>Insira seu e-mail para receber o link de recuperação de senha.</p>
              <InputText name="email" icon={FiMail} placeholder="E-mail" type="email" />
              <footer>
                <Button type="submit">Recuperar</Button>
                <Link to="/">Voltar para login</Link>
              </footer>
            </Form>
          )}
        </ContentBlock>
      </Content>
    </Container>
  )
}

export default Recovery
