import styled from 'styled-components'

interface ContainerProps {
  hasMessages: boolean
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  right: ${props => (props.hasMessages ? '0px' : '-100%')};
  top: 90px;
  padding: 30px;
  overflow: hidden;
  z-index: 9999;
`
