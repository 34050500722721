import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../../components/Button'
import CoinsAndValue from '../../../components/CoinsAndValue'
import { useMissionStats } from '../../../hooks/missionStats'

import { Container } from './styles'

const Finished: React.FC = () => {
  const { missionStats } = useMissionStats()

  const history = useHistory()

  const [textCoin, setTextCoin] = useState('')
  const [normalizedValue, setNormalizedValue] = useState(0)

  useEffect(() => {
    if (missionStats?.budget > 0) {
      setTextCoin('Ganho')
      setNormalizedValue(missionStats.budget)
    } else {
      setTextCoin('Perda')
      if (missionStats.budget < 0) {
        setNormalizedValue(missionStats.budget * -1)
      }
    }
  }, [missionStats])

  return (
    <>
      <Container>
        <h1>Missão Concluída!</h1>
        <p>Você respondeu todas as perguntas dessa etapa.</p>
        <p>
          Esse foi o impacto no seu patrimônio do jogo considerando o seu desempenho na
          missão:
        </p>

        <CoinsAndValue value={normalizedValue} text={textCoin} />
        {!!parseInt(missionStats.bonusBudget.toString(10), 10) && (
          <>
            <h3>Você ganhou um bônus por conta da velocidade que você respondeu</h3>

            <CoinsAndValue value={missionStats.bonusBudget} text="Bônus" />
          </>
        )}
      </Container>
      <Button type="button" onClick={() => history.push('/dashboard')}>
        Voltar
      </Button>
    </>
  )
}

export default Finished
