import React from 'react'

import { useLayoutInfos } from '../../../../hooks/layoutInfos'

import { ToggleMenuProvider } from '../../../../hooks/toggleMenu'

import Burger from './Burger'
import Menu from './Menu'

import gameLogo from '../../../../assets/logo-aec.png'
import { useAuth } from '../../../../hooks/auth'

import { Container, Left, Center, Right } from './styles'

const Header: React.FC = () => {
  const { layoutInfos } = useLayoutInfos()

  const { user } = useAuth()

  return (
    <Container>
      <div>
        <Left>
          <img src={gameLogo} alt="Trilha Certa" />
        </Left>
        <Center>
          <h2>{layoutInfos?.title}</h2>
          <p>{layoutInfos?.description}</p>
        </Center>
        <Right>
          {!!user && (
            <ToggleMenuProvider>
              <Burger />
              <Menu />
            </ToggleMenuProvider>
          )}
        </Right>
      </div>
    </Container>
  )
}

export default Header
