import React, { useCallback, useState } from 'react'
import Button from '../../../../components/Button'
import CoinsAndValue from '../../../../components/CoinsAndValue'
import { useMissionStats } from '../../../../hooks/missionStats'
import { useToast } from '../../../../hooks/toast'
import { useUserStats } from '../../../../hooks/userStats'
import api from '../../../../services/api'

import { Container, GroupButtons } from './styles'

interface Question {
  id: string
  missionId: string
  questionTypeId: string
  budgetValue: number
}

interface PositiveNegativeQuestion {
  id: string
  questionId: string
  questionContent: string
  answerIfPositive: string
  answerIfNegative: string
}
export interface QuestionWithContent extends Question {
  questionData: PositiveNegativeQuestion
}

interface Props {
  question: QuestionWithContent
}

interface AnswerProps {
  answeredPositive: boolean
}

const PositiveNegative: React.FC<Props> = ({ question }) => {
  const { missionStats, updateMissionStats } = useMissionStats()
  const { updateUserStats } = useUserStats()
  const { addToast } = useToast()

  const [answered, setAnswered] = useState(false)

  const [lastAnswer, setLastAnswer] = useState<AnswerProps>({} as AnswerProps)

  const submitAnswer = useCallback(
    async (newAnswer: boolean) => {
      try {
        const response = await api.post(
          `/missions/${question.missionId}/questions/${question.id}/answers`,
          {
            answer: {
              answeredPositive: newAnswer,
              positiveNegativeQuestionId: question.questionData.id,
              missionStatsId: missionStats.id,
            },
          },
        )

        const { answer } = response.data
        setLastAnswer(answer)
        const {
          missionStats: atualizedMissionStats,
          userStats: atualizedUserStats,
        } = response.data.stats

        updateUserStats()
        setAnswered(true)
        // updateMissionStats(question.missionId)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Falha ao salvar resposta',
          description: err.message,
        })
      }
    },
    [
      addToast,
      missionStats.id,
      question.id,
      question.missionId,
      question.questionData.id,
      updateUserStats,
    ],
  )

  const handleAnswerPositive = useCallback(() => {
    submitAnswer(true)
  }, [submitAnswer])
  const handleAnswerNegative = useCallback(() => {
    submitAnswer(false)
  }, [submitAnswer])

  const handleContinue = useCallback(async () => {
    if (answered) {
      await updateMissionStats(question.missionId)
      setAnswered(false)
    }
  }, [answered, question.missionId, updateMissionStats])

  return (
    <Container>
      <header>
        <p>Positivo/Negativo</p>
      </header>
      <main>
        {!answered && (
          <>
            <p>{question.questionData.questionContent}</p>
            <GroupButtons>
              <Button
                type="button"
                backgroundColor="green"
                onClick={handleAnswerPositive}
              >
                Positivo
              </Button>
              <Button type="button" backgroundColor="red" onClick={handleAnswerNegative}>
                Negativo
              </Button>
            </GroupButtons>
          </>
        )}
        {answered && (
          <>
            {lastAnswer?.answeredPositive && (
              <>
                <h1>Parabéns!</h1>
                <p>{question.questionData.answerIfPositive}</p>
              </>
            )}
            {!lastAnswer?.answeredPositive && (
              <>
                <h1>Fique Atento!</h1>
                <p>{question.questionData.answerIfNegative}</p>
              </>
            )}
            <GroupButtons>
              <Button type="button" onClick={handleContinue}>
                Continuar
              </Button>
            </GroupButtons>
          </>
        )}
      </main>
      <footer>
        <p>Essa questão vale</p>
        <CoinsAndValue value={question.budgetValue} text="Patrimônio" />
      </footer>
    </Container>
  )
}

export default PositiveNegative
