import React, { createContext, useCallback, useState, useContext } from 'react'

import api from '../services/api'

interface MissionStats {
  budget: number
  bonusBudget: number
  currentQuestionId: string
  missionId: string
  userId: string
  id: string
  finalizedAt?: Date
}

interface MissionStatsContextData {
  missionStats: MissionStats

  setMissionStats(missionStats: MissionStats): void
  updateMissionStats(missionId: string): Promise<void>
  clearMissionStats(): void
}

const MissionStatsContext = createContext<MissionStatsContextData>(
  {} as MissionStatsContextData,
)

const MissionStatsProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<MissionStats>(() => {
    const missionStats = localStorage.getItem(
      `@${process.env.REACT_APP_PERSISTED_STATE_KEY}:missionStats`,
    )

    if (missionStats) {
      return JSON.parse(missionStats)
    }
    return {} as MissionStats
  })

  const setMissionStats = useCallback(missionStats => {
    localStorage.setItem(
      `@${process.env.REACT_APP_PERSISTED_STATE_KEY}:missionStats`,
      JSON.stringify(missionStats),
    )

    setData(missionStats)
  }, [])

  const updateMissionStats = useCallback(
    async (missionId: string) => {
      const response = await api.get(`/missions/${missionId}/stats`)
      setMissionStats(response.data.missionStats)
    },
    [setMissionStats],
  )

  const clearMissionStats = useCallback(() => {
    localStorage.removeItem(`@${process.env.REACT_APP_PERSISTED_STATE_KEY}:missionStats`)
    setData({} as MissionStats)
  }, [])

  return (
    <MissionStatsContext.Provider
      value={{
        missionStats: data,
        setMissionStats,
        updateMissionStats,
        clearMissionStats,
      }}
    >
      {children}
    </MissionStatsContext.Provider>
  )
}

function useMissionStats(): MissionStatsContextData {
  const context = useContext(MissionStatsContext)
  if (!context) {
    throw new Error('useMissionStats must be used within an MissionStatsProvider')
  }
  return context
}

export { MissionStatsProvider, useMissionStats }
