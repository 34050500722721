import React from 'react'
import { useLayoutInfos } from '../../../hooks/layoutInfos'
import Header from './Header'

import { Container, OutHeader, Content } from './styles'

const Default: React.FC = ({ children }) => {
  const { layoutInfos } = useLayoutInfos()

  return (
    <Container background={layoutInfos.background}>
      <Header />
      <OutHeader>
        <Content>{children}</Content>
      </OutHeader>
    </Container>
  )
}

export default Default
