import styled from 'styled-components'

export const Container = styled.div`
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${props => props.theme.colors.backgroundSecondary};
  }

  main {
    max-width: 550px;

    margin: 6px 0px 36px;
    h1 {
      text-align: center;
    }
    p {
      font-size: 22px;
      text-align: justify;
    }
  }
  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
export const GroupButtons = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  button {
    margin-top: 0;
  }
`
