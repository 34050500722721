import styled from 'styled-components'

export const Container = styled.header`
  height: 80px;
  display: flex;
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 100;
  opacity: 1;
  padding: 16 32px;
  justify-content: center;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.colors.backgroundSecondary};
  color: ${props => props.theme.colors.textSecondary};

  > div {
    width: 100%;
    max-width: 1600px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    position: relative;
  }

  @media screen and (min-width: 800px) {
    position: fixed;
  }
`

export const Left = styled.div`
  display: none;

  img {
    height: 60px;
    max-width: 300px;
  }

  @media screen and (min-width: 610px) {
    display: block;
    img {
      max-height: 60px;
    }
  }

  @media screen and (min-width: 800px) {
    img {
      max-height: 70px;
    }
  }

  @media screen and (min-width: 990px) {
    img {
      max-height: 80px;
    }
  }
`
export const Center = styled.div`
  text-align: left;
  flex: 1;
  padding: 0px 5px;

  @media screen and (min-width: 610px) {
    text-align: center;
  }
`
export const Right = styled.div``
