import brfprevidencia from './01-brfprevidencia.png'
import casaprev from './02-casaprev.png'
import celos from './03-celos.png'
import datusprev from './04-datusprev.png'
import elos from './05-elos.png'
import scprev from './06-scprev.png'
import fumpresc from './07-fumpresc.png'
import previsc from './08-previsc.png'
import prevunisul from './09-prevunisul.png'
import fusesc from './10-fusesc.png'
import previg from './11-previg.png'
import sulprevidencia from './12-sulprevidencia.png'
import quanta from './13-quanta.png'
import oabprev from './14-oabprev.png'

export const entidadesList = [
  brfprevidencia,
  casaprev,
  celos,
  datusprev,
  elos,
  scprev,
  fumpresc,
  previsc,
  prevunisul,
  fusesc,
  previg,
  sulprevidencia,
  quanta,
  oabprev,
]
