import React, { useEffect } from 'react'
import { useAuth } from '../../../hooks/auth'
import { useLayoutInfos } from '../../../hooks/layoutInfos'
import Default from '../Default'
import Main from './Main'
import Sidebar from './Sidebar'

import { Container } from './styles'

const Logged: React.FC = ({ children }) => {
  const { user } = useAuth()
  const { setLayoutInfos } = useLayoutInfos()

  useEffect(() => {
    setLayoutInfos({
      title: 'Bem-vindo!',
      description: `${user?.name.split(' ')[0]}
      , chegou a hora de aprender e testar seus conhecimentos!`,
    })
  }, [setLayoutInfos, user?.name])
  return (
    <Default>
      <Sidebar />
      <Main>{children}</Main>
    </Default>
  )
}

export default Logged
