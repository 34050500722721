import styled from 'styled-components'

export const Container = styled.button`
  background: transparent;
  border: none;
  padding: 8px;
  height: 46px;
  width: 46px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    color: #1c1c1c;
  }

  color: #000;

  &::before {
    content: none;
    font-size: 0px;
  }
  > svg {
    background-color: #fff;
    border-radius: 50%;
  }
`
