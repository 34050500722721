import React from 'react'

import { useHistory } from 'react-router-dom'
import {
  Container,
  DivButtons,
  DivEntidades,
  DivPatrocinadores,
  DivPremios,
  Footer,
  WoodBoard,
  Wrapper,
} from './styles'

import imgPremios from '../../assets/premios-img.png'
import imgTitlePremios from '../../assets/premiacoes-title.png'
import imgBtnJogar from '../../assets/btn-jogar.png'
import imgBtnRegulamento from '../../assets/btn-regulamento.png'
import logoHome from '../../assets/logo-home-nova.png'

import regulamentoPdf from '../../assets/regulamento.pdf'

import { entidadesList } from '../../assets/entidades'
import { patrocinadoresList } from '../../assets/patrocinadores'

const NewHome: React.FC = () => {
  const history = useHistory()

  return (
    <Container>
      <Wrapper>
        <img src={logoHome} alt="Trilha Certa" />
        <WoodBoard>
          <p>
            Olá, tudo bem? Bem-vindo(a) ao jogo Trilha Certa! Nesse jogo, você vai
            aprender sobre diversos temas de educação financeira e previdenciária,
            refletir sobre os seus hábitos, ter chance de ganhar prêmios e se divertir
            muito enquanto aprende. Para começar a jogar, é so clicar no botão abaixo
            “JOGAR” e para ler as regras do jogo e da permissão é só clicar em
            “REGULAMENTO”.
          </p>
          <DivButtons>
            <button type="button" onClick={() => history.push('/start')}>
              <img src={imgBtnJogar} alt="Jogar" />
            </button>
            <button type="button" onClick={() => window.open(regulamentoPdf)}>
              <img src={imgBtnRegulamento} alt="Ler Regulamento" />
            </button>
          </DivButtons>
          <DivPremios>
            <img src={imgPremios} alt="Prêmios" />
            <div>
              <article>
                <img src={imgTitlePremios} alt="Premiações" />
                <div>
                  <ol>
                    <li>SmartTV de 43&quot;</li>
                    <li>Bicicleta Ergométrica</li>
                    <li>Robô Aspirador</li>
                    <li>Nespresso</li>
                    <li>Air Fryer</li>
                    <li>um ano de HBO Max</li>
                    <li>um ano de Amazon Prime</li>
                    <li>um ano de Amazon Prime</li>
                    <li>um ano de Amazon Prime</li>
                    <li>um ano de Amazon Prime</li>
                  </ol>
                </div>
              </article>
              <p className="dt-participacao">
                Data de participação:
                <br />
                de 22/11/2021 a 22/12/2021
              </p>
            </div>
          </DivPremios>
        </WoodBoard>
        <Footer>
          <DivEntidades>
            <p>Entidades participantes</p>
            {/* <img src={entidadesMobileImg} alt="Entidades" id="entidadesMobile" />
            <img src={entidadesImg} alt="Entidades" id="entidades" /> */}
            <div>
              {entidadesList.map(entidade => (
                <img src={entidade} alt="Entidade" />
              ))}
            </div>
          </DivEntidades>
          <DivPatrocinadores>
            <p>Patrocinadores institucionais</p>
            <div>
              {patrocinadoresList.map(patrocinador => (
                <img src={patrocinador} alt="Patrocinador institucional" />
              ))}
            </div>
          </DivPatrocinadores>
        </Footer>
      </Wrapper>
    </Container>
  )
}

export default NewHome
