import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'

import { Container } from './styles'

import regulamentoPdf from '../../assets/regulamento.pdf'

const Infos: React.FC = () => {
  const { user, updateProfile } = useAuth()
  const history = useHistory()
  const { addToast } = useToast()

  const handleStartGame = useCallback(async () => {
    if (!user.userDetails?.firstAccess) {
      api.patch('/users/firstAccess').then(response => {
        const { user: updatedUser, userDetails: updatedUserDetails } = response.data

        updateProfile({ ...updatedUser, userDetails: updatedUserDetails })

        addToast({
          type: 'info',
          title: 'Começando o jogo',
          description: '',
        })

        history.push('/dashboard')
      })
    } else {
      history.push('/dashboard')
    }
  }, [addToast, history, updateProfile, user.userDetails?.firstAccess])

  return (
    <Container>
      <h1>Informações</h1>
      <p>
        Esse game foi criado para você aprender um pouco mais sobre previdência e refletir
        sobre como você lida com o dinheiro. Para que o objetivo do jogo seja cumprido, é
        fundamental que você jogue da maneira mais sincera possível.
      </p>
      <p>
        Inventamos uma moeda exclusiva para este game, chamada de CertezaCoin (C$). Ela
        ajudará a medir o quanto você tem bons hábitos financeiros.
      </p>
      <p>
        Com base no seu desempenho, seu patrimônio dentro do jogo vai alterando. Todos
        começam com C$ 0,00. Caso você acerte a resposta seu patrimônio aumenta, caso erre
        seu patrimônio diminui. Quanto mais rápido você responder de forma correta, você
        também ganha um bônus em C$.
      </p>
      <p>
        <button type="button" onClick={() => window.open(regulamentoPdf)}>
          Clique aqui para ler o regulamento completo
        </button>
      </p>
      {/* <h2>Regras do Game</h2>
      <p>
        Após você realizar seu cadastro, você irá começar a jogar. Existem diversos tipos
        de jogos, alguns com conteúdo educacional sobre algum tema específico e outros que
        buscam compreender como você se comporta financeiramente. Com base nas suas
        respostas, o seu custo de vida anual vai aumentar ou diminuir.
      </p>
      <p>
        Lembre-se: esse jogo é apenas uma reflexão à respeito dos seus hábitos. Tente ser
        o mais sincero possível em suas respostas.
      </p>
      <p>
        O campeão (top 1) do ranking geral e do ranking da sua entidade ganharão brindes
        do Programa A Escolha Certa! O ranking será fechado dia 30/07. Você pode
        acompanhar o ranking clicado no menu. Bom jogo!
      </p> */}
      {!user.userDetails?.firstAccess && (
        <Button type="button" onClick={handleStartGame}>
          Começar!
        </Button>
      )}
      {user.userDetails?.firstAccess && (
        <Button type="button" onClick={() => history.push('/dashboard')}>
          Voltar
        </Button>
      )}
    </Container>
  )
}

export default Infos
