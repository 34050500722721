import { isAfter, isBefore } from 'date-fns'
import React from 'react'
import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom'

import { useAuth } from '../hooks/auth'

import Default from '../pages/_layouts/Default'
import Logged from '../pages/_layouts/Logged'

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean
  component: React.ComponentType
  isHome?: boolean
  isStart?: boolean
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  isHome = false,
  isStart = false,
  ...rest
}) => {
  const { user, signOut } = useAuth()

  console.log(new Date(), new Date(2021, 11, 23, 0))

  if (!isStart && isAfter(new Date(), new Date(2021, 11, 23, 0))) {
    if (user) signOut()

    return (
      <ReactDOMRoute
        {...rest}
        render={({ location }) => {
          return (
            <Redirect
              to={{
                state: { from: location },
                pathname: '/start',
              }}
            />
          )
        }}
      />
    )
  }

  let PageRendered: JSX.Element

  if (isPrivate) {
    PageRendered = (
      <Logged>
        <Component />
      </Logged>
    )
  } else {
    PageRendered = (
      <Default>
        <Component />
      </Default>
    )
  }

  if (isPrivate === !!user) {
    if (isHome) {
      return <ReactDOMRoute {...rest} render={() => <Component />} />
    }
    return <ReactDOMRoute {...rest} render={() => PageRendered} />
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return (
          <Redirect
            to={{
              state: { from: location },
              pathname: isPrivate ? '/' : '/dashboard',
            }}
          />
        )
      }}
    />
  )
}

export default Route
