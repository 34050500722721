import React, { useEffect } from 'react'
import CoinsAndValue from '../../components/CoinsAndValue'
import { useAuth } from '../../hooks/auth'
import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useUserStats } from '../../hooks/userStats'
import formatValue from '../../utils/formatValue'
import Simulator from './Simulator'

import { Container } from './styles'

const GameFinished: React.FC = () => {
  const { userStats } = useUserStats()
  const { user } = useAuth()
  const { setLayoutInfos } = useLayoutInfos()
  useEffect(() => {
    setLayoutInfos({
      title: 'Game Over!',
      description: `${user?.name.split(' ')[0]}${''}
      , você passou por todas as etapas do game. Veja seu resultado.`,
    })
  }, [setLayoutInfos, user?.name])
  return (
    <Container>
      <h1>Você concluiu o game!</h1>

      {userStats && userStats.currentBudget > userStats.initialBudget && (
        <>
          <header>
            <p>
              Após responder todas as perguntas, você conseguiu aumentar seu patrimônio!
              <br />
              Seu patrimônio é de:
            </p>
            <CoinsAndValue text="Patrimônio Total" value={userStats.currentBudget} />
            <h3>Já pensou que você poderia usar esse valor na previdência? </h3>
            <p>
              Veja uma simulação investindo seu patrimônio de{' '}
              <strong>{formatValue(userStats.currentBudget)}</strong> durante{' '}
              <strong>15 anos</strong>
            </p>
            <small>
              <i>
                O saldo projetado é estimado e foi rentabilizado utilizando uma taxa de 8%
                ao ano.
              </i>
            </small>
          </header>
          <Simulator />
        </>
      )}

      {userStats && userStats.currentBudget < userStats.initialBudget && (
        <>
          <header>
            <p>
              Após responder todas as perguntas, você acabou perdendo patrimônio.
              <br />
              Seu patrimônio ficou negativo em:
            </p>
            <CoinsAndValue
              text="Perda"
              value={userStats.initialBudget - userStats.currentBudget}
            />
            <h3>
              Tome cuidado! Que tal mudar seus hábitos para ter uma vida mais saudável?
            </h3>
          </header>
          <main>
            <p>Segue aqui algumas dicas pra te ajudar a ter uma vida mais saudável!</p>
            <ol>
              <li>
                Pratique atividade física. Elas vão te ajudar a desestressar, se manter em
                forma e são importantes aliadas no combate a uma série de doenças.{' '}
              </li>
              <li>
                Tenha uma alimentação saudável. Comer bem começa por aquilo que escolhemos
                comer por isso prefira alimentos naturais e frescos.{' '}
              </li>
              <li>
                Durma bem. Dormir pouco ou mal é prejudicial à saúde. Uma boa qualidade de
                sono te ajudará a ter uma vida melhor e mais longa.{' '}
              </li>
              <li>
                Evite o uso abusivo de álcool e outras drogas. Fique atento ao consumo de
                álcool e outras drogas. O uso abusivo, além de trazer consequências
                físicas podem levar a danos psicológicos e sociais.{' '}
              </li>
              <li>
                Faça atividades com amigos e família. Manter relacionamentos saudáveis,
                ter uma rede de apoio e suporte faz parte de uma vida equilibrada e
                saudável.{' '}
              </li>
              <li>
                Tenha tempo para você! Cuide-se. Faça algo por você. Tenha um hobby.
                Aprecie sua companhia. Uma pessoa feliz consigo mesma é bem saudável.
              </li>
            </ol>
          </main>
        </>
      )}
    </Container>
  )
}

export default GameFinished
