import React, { useEffect } from 'react'

import Avatars, { Options } from '@dicebear/avatars'
import sprites from '@dicebear/avatars-avataaars-sprites'
import SpriteOptions from '@dicebear/avatars-avataaars-sprites/lib/options'

import { Container } from './styles'
import usePersistedState from '../../hooks/usePersistedState'

interface Props {
  avatar: string | null
  name: string
  size?: number
  sizeToggled?: number
  toggled?: boolean
}

const Avatar: React.FC<Props> = ({
  avatar,
  name = 'User',
  size = 30,
  sizeToggled = 30,
  toggled = false,
}) => {
  const [tmpAvatar, setTmpAvatar] = usePersistedState('avatar', '')

  useEffect(() => {
    const options: Options & SpriteOptions = {
      mode: 'exclude',
      style: 'circle',
      mouth: ['concerned', 'disbelief', 'eating', 'grimace', 'sad', 'scream'],
      eyebrow: ['angry', 'sad'],
      eyes: ['cry'],
    }
    const avatars = new Avatars(sprites, { base64: true, ...options })
    const svg = avatars.create(name.replace(' ', '-'))

    setTmpAvatar(svg)
  }, [name, setTmpAvatar])

  const linkAvatar = avatar
    ? `${process.env.REACT_APP_API_URL}/files/${avatar}`
    : `https://ui-avatars.com/api/?size=128&background=ed8b27&name=${name}&rounded=true&bold=true`

  return (
    <Container size={size} sizeToggled={sizeToggled} toggled={toggled}>
      <img src={linkAvatar} alt={name} />
    </Container>
  )
}

export default Avatar
