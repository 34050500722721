/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components'

interface ContainerProps {
  color?: 'white' | 'green' | 'pink' | 'purple'
  size?: 'large' | 'normal'
  gradientDirection?: 'left' | 'right'
  displayed?: boolean
}

const getGradientAndShadow = (
  color1: string,
  color2: string,
  direction: ContainerProps['gradientDirection'],
  shadowColor: string,
) => css`
  ${direction === 'right'
    ? css`
        background-image: linear-gradient(60deg, ${color1}, ${color2});
      `
    : css`
        background-image: linear-gradient(60deg, ${color2}, ${color1});
      `}
  box-shadow: 0px 12px 16px ${shadowColor};
`

const colorTypesVariation = {
  green: {
    left: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#6DE381', '#31D19E', 'left', 'rgba(54, 211, 156,0.6)')}
    `,
    right: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#6DE381', '#31D19E', 'right', 'rgba(54, 211, 156,0.6)')}
    `,
  },

  pink: {
    left: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#FBADB4', '#EB6A9F', 'left', 'rgba(250, 168, 179,0.6)')}
    `,
    right: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#FBADB4', '#EB6A9F', 'right', 'rgba(250, 168, 179,0.6)')}
    `,
  },
  purple: {
    left: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#9C9EFF', '#7A71EF', 'left', 'rgb(209, 212, 226)')}
    `,
    right: css`
      color: ${({ theme }) => theme.colors.textSecondary};
      ${getGradientAndShadow('#9C9EFF', '#7A71EF', 'right', 'rgb(209, 212, 226)')}
    `,
  },
  white: {
    left: css`
      color: ${({ theme }) => theme.colors.text};
      background-color: #ffffff;
      box-shadow: 0px 12px 16px rgba(101, 101, 101, 0.09);
      border: 1px solid #eaeaea;
    `,
    right: css`
      color: ${({ theme }) => theme.colors.text};
      background-color: #ffffff;
      box-shadow: 0px 12px 16px rgba(101, 101, 101, 0.09);
      border: 1px solid #eaeaea;
    `,
  },
}

export const Container = styled.div<ContainerProps>`
  ${props =>
    colorTypesVariation[props.color || 'white'][props.gradientDirection || 'right']}
  border-radius:4px;
  padding: ${props => (props.displayed ? '10px' : '0px')};
  width: ${props => (props.size === 'large' ? '100%' : '160px')};
  pointer-events: ${props => (props.displayed ? 'all' : 'none')};
  overflow: none;

  transition: 0.2s all ease-in-out;

  min-height: ${props => (props.displayed ? '85px' : '0px')};
  height: ${props => (props.displayed ? '100%' : '0px')};

  opacity: ${props => (props.displayed ? 1 : 0)};

  @media screen and (min-width: 766px) {
    width: ${props => (props.size === 'large' ? '100%' : '200px')};
  }
`
