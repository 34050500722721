import styled, { css } from 'styled-components'

import { tint, shade } from 'polished'

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  flex: 1;

  display: flex;

  justify-content: center;
`

export const Content = styled.div`
  max-width: 600px;

  width: 100%;
`

export const ContentBlock = styled.div`
  padding: 15px 20px;

  border: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};

  border-radius: 5px;

  background-color: ${({ theme }) => theme.colors.backgroundTertiary};

  /* border: 1px solid black; */

  > form {
    h1 {
      text-align: center;
      margin-bottom: 12px;
    }
    p {
      text-align: center;
      margin-bottom: 12px;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > button {
      margin-top: 0;
    }
    > a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.backgroundSecondary};

      transition: all 0.2s ease-in-out;
      &:hover {
        color: ${({ theme }) => shade(0.1, theme.colors.backgroundSecondary)};
      }
    }
  }
`
