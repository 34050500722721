export default {
  title: 'light',
  colors: {
    primary: '#FDA10D',
    secondary: '#EE9608',
    tertiary: '#DE8A02',

    background: '#E1F4F4',
    backgroundSecondary: '#B26160',
    backgroundTertiary: '#E7CCCD',

    placeholder: '#666360',
    text: '#333',
    textSecondary: '#E1F4F4',

    golden: '#FAC700' /* '#B67102' /* '#F29602', */,
    blue: '#8493F4',
    blueSecondary: '#D1D4E2',
    error: '#fddede',
    errorText: '#C53030',
  },
}
