import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useLayoutInfos } from '../../hooks/layoutInfos'

import { useMissionStats } from '../../hooks/missionStats'
import { useToast } from '../../hooks/toast'

import { Container } from './styles'
import api from '../../services/api'
import Finished from './Finished'
import Playing, { QuestionWithContent } from './Playing'
import Button from '../../components/Button'

interface MissionData {
  id: string
  name: string
  description: string
  picture: string
}

const Game: React.FC = () => {
  const { setLayoutInfos } = useLayoutInfos()
  const { missionId } = useParams<{ missionId: string }>()
  const { addToast } = useToast()
  const { setMissionStats, missionStats, updateMissionStats } = useMissionStats()

  const history = useHistory()

  const [loadStats, setLoadStats] = useState(false)

  const [mission, setMission] = useState<MissionData>({} as MissionData)

  const [questions, setQuestions] = useState<QuestionWithContent[]>()

  const [finalizou, setFinalizou] = useState(false)

  useEffect(() => {
    if (loadStats && !!missionStats?.finalizedAt) {
      setFinalizou(true)
    }
  }, [loadStats, missionStats])

  useEffect(() => {
    setLoadStats(false)
    setFinalizou(false)
    async function loadMission(): Promise<void> {
      try {
        const response = await api.get(`/missions/${missionId}`)

        const { mission: thisMission } = response.data

        setMission(thisMission)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar missão',
          description: err.message,
        })

        history.push('/dashboard')
      }
    }

    async function loadQuestions(): Promise<void> {
      try {
        const response = await api.get(`/missions/${missionId}/questions`)

        const { questions: missionQuestions } = response.data
        setQuestions(missionQuestions)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao carregar questões',
          description: err.message,
        })
        history.push('/dashboard')
      }
    }

    async function loadMissionStats(): Promise<void> {
      await updateMissionStats(missionId)
      setLoadStats(true)
    }

    loadMission().then(() => {
      setLayoutInfos({
        title: mission?.name,
        description: mission?.description,
      })
    })
    loadQuestions()
    loadMissionStats()
  }, [
    addToast,
    history,
    mission?.description,
    mission?.name,
    missionId,
    setLayoutInfos,
    setMissionStats,
    updateMissionStats,
  ])

  return (
    <Container>
      {mission && finalizou && <Finished />}
      {mission && !finalizou && questions && <Playing questions={questions} />}
      <footer>
        <Button type="button" onClick={() => history.push(`/mission/${missionId}/intro`)}>
          Introdução
        </Button>

        <Button type="button" onClick={() => history.push(`/dashboard`)}>
          Página Inicial
        </Button>
      </footer>
    </Container>
  )
}

export default Game
