import React from 'react'

import { Container } from './styles'

import coinsImage from '../../assets/coins.svg'
import formatValue from '../../utils/formatValue'

interface Props {
  value: number
  text: string
}

const CoinsAndValue: React.FC<Props> = ({ value, text }) => {
  return (
    <Container>
      <img src={coinsImage} alt="Coins" />
      <div>
        <span>{formatValue(value)}</span>
        <p>{text ?? 'Valor'}</p>
      </div>
    </Container>
  )
}

export default CoinsAndValue
