import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 75px;
  margin-top: 5px;
  > img {
    width: 60px;
    margin-right: 12px;
  }
  > div {
    display: flex;
    flex-direction: column;

    > p {
      font-size: 20px;
      text-align: center;
    }
    > span {
      font-size: 28px;
      font-weight: 700;
    }
  }
`
